import { BrowserTracing, init } from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { HydratedRouter } from 'react-router/dom';
import { ENVIRONMENT, SENTRY_DSN } from 'src/constants/parameters.client';
import './handleMissingDynamicImport';

function App(): React.ReactElement {
  if (ENVIRONMENT === 'prd' && SENTRY_DSN) {
    init({
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      release: __SENTRY_RELEASE_VERSION__,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }

  return <HydratedRouter />;
}

ReactDOM.hydrateRoot(document, <App />);
